* {
  font-family: 'Roboto', sans-serif;
  color: #2b2b2b;
}

.dark {
  color: white;
}

::selection {
  background-color: #97ffd5;
  color: white;
}