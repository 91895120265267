.input {
    margin-bottom: 40px;
}

.text-input {
    width: 100%;
}

.input label {
    color: #a7a7a7;
    font-family: 'Montserrat', sans-serif;
}

.text-input input {
    width: 100%;
    border: none;
    background-color: transparent;
    border-bottom: solid #dadada 1px;
    padding: 10px 5px;
    font-size: 15px;
    transition: border-bottom ease-in-out 0.2s;
}

.text-input input::placeholder {
    color: #dadada;
}

.text-input input:focus {
    outline: none;
    border-bottom-color: #003eff;
}