.city-entry {
    box-shadow: 3px 6px 11px -5px #657cc529;
    border-radius: 10px;
    margin-bottom: 30px;
    transform: scale(1);
    background-image: linear-gradient(45deg, #5ddea8, #c5f9e9);
    transition: all ease-in-out 0.2s;
}

.dark .city-entry {
    box-shadow: 0px 0px 11px 0px #060606;
}

.city-entry:not(.details-on):hover {
    transform: scale(1.05);
}

.city-entry .city-entry-summary {
    padding: 15px 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    cursor: pointer;
}

.dark .city-entry .city-entry-details span {
    color: white;
}

.city-entry .city-entry-details {
    background-color: white;
    border-radius: 10px;
    height: 0px;
    padding: 0px 50px;
    overflow: hidden;
    transition: height ease-in-out 0.5s, padding ease-in-out 0.5s, transform ease-in-out 0.5s;
}

.dark .city-entry .city-entry-details {
    background-color: #0e0e0e;
}

.city-entry.details-on .city-entry-details {
    box-shadow: 3px 6px 11px -5px #657cc529;
    transform: scale(1.04);
    height: 175px;
    padding: 35px 50px;
}

.dark .city-entry.details-on .city-entry-details {
    box-shadow: 0px 0px 11px 0px #060606;
}

.city-entry.cold {
    background-image: linear-gradient(45deg, #2b8ce8, #90fff5);
}

.city-entry.warm {
    background-image: linear-gradient(45deg, #f5825e, #ffd582);
}

.dark .city-entry.cold {
    background-image: linear-gradient(45deg, #143694, #5c91c1);
}

.dark .city-entry.warm {
    background-image: linear-gradient(45deg, #4e3da7, #db74b0);
}

.city-entry .left {
    align-self: flex-end;
}

.city-entry .left span,
.city-entry .left span i {
    color: white;
}

.city-entry .left span:first-child {
    font-size: 60px;
    margin-right: 10px;
}

.city-entry .center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: end;
    margin-bottom: 5px;
    margin-left: 30px;
}

.city-entry .center span {
    font-size: 20px;
    color: white;
}

.city-entry .center div span:nth-child(2) {
    font-size: 35px;
}

.city-entry .center div span:nth-child(1) {
    margin-right: 5px;
}

.city-entry .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: end;
    margin-right: 20px;
}

.city-entry .right span {
    color: white;
}

.city-entry .remove-button {
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
}

.city-entry .remove-button i {
    color: #1b1b1b1f;
}

.city-entry .remove-button:hover i {
    color: #1b1b1b61;
    transition: color ease-in-out 0.2s;
}

.city-entry-placeholder {
    border-radius: 10px;
    margin-bottom: 30px;
    transform: scale(1);
    transition: all ease-in-out 0.2s;
    height: 125px;
    animation-duration: 2s;
    animation-name: loading;
    animation-iteration-count: infinite;
}

@keyframes loading {
    0% {
        background-color: #a5a5a521;
    }
  
    50% {
        background-color: #a5a5a545;
    }

    100% {
        background-color: #a5a5a521;
    }
}