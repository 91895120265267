.icon-button {
    background: none;
    border: none;
    font-size: 15px;
    cursor: pointer;
}

.icon-button i {
    color: #a7a7a7;
}

.dark .icon-button i {
    color: white;
}

.icon-button:focus {
    outline: none;
}