.checkbox-input input {
    opacity: 0;
    position: absolute;
    top: -1000px;
}

.checkbox-input label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    color: #a7a7a7;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.dark .checkbox-input label {
    color: white;
}

.checkbox-input label::before {
    content: '';
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    transition: border-color ease-in-out 0.2s;
}

.dark .checkbox-input label::before {
    border-color: white;
}

.checkbox-input input:focus + label::before {
    border-color: #003eff;
}

.checkbox-input input:checked + label::after {
    content: '';
    position: absolute;
    left: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    background-image: linear-gradient(#003eff 0%, #0032ce 75%, #002496 100%);
    border-radius: 3px;
}