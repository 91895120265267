.search-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.search-box form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    align-self: center;
    background-color: white;
    padding: 7px 40px;
    border-radius: 100px;
    box-shadow: 3px 6px 11px -5px #657cc529;
}

.dark .search-box form {
    background-color: #0e0e0e;
    box-shadow: 0px 0px 11px 0px #060606;
}

.search-box .text-input {
    margin-right: 70px;
    margin-bottom: 0;
}

.dark .search-box .text-input input {
    color: white;
}

.search-box .text-input input {
    border: none;
}

.search-box .text-input input::placeholder {
    color: #a7a7a7;
}

.dark .search-box .text-input input::placeholder {
    color: white;
}

.search-box .search-button {
    background: none;
    border: none;
}

.search-box .icon-button i {
    font-size: 17px;
}

.search-box .search-results {
    padding: 0 25vw;
    margin-top: 15px;
}

.search-box .search-results .search-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 0 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 6px 6px 11px -5px #657cc529;
}

.dark .search-box .search-results .search-entry {
    background-color: #0e0e0e;
    box-shadow: 0px 0px 11px 0px #060606;
}

.dark .search-box .search-results .search-entry p,
.dark .search-box .search-results .search-entry span {
    color: white;
}

.search-box .search-results .search-entry span {
    font-weight: 600;
}

.search-box .warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 0 20px;
    border-radius: 5px;
    box-shadow: 6px 6px 11px -5px #657cc529;
    background-color: #fff3cd;
    border: solid 1px #ffeeba;
}

.search-box .warning p {
    color: #856404;
}