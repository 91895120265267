.navbar {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    display: grid;
    grid-template: 1fr / 150px 1fr 150px 150px;
    align-items: center;
    width: calc(100vw - 40px * 2);
    height: 100px;
    padding: 0 40px;
    box-shadow: 0px 0px 11px 0px #657cc529;
    transition: background-color ease-in-out 0.2s;
}

.dark .navbar {
    background-color: #0e0e0e;
    box-shadow: 0px 0px 11px 0px #060606;
}

.navbar .logout-button,
.navbar .checkbox-input {
    justify-self: end;
}

.navbar .logo {
    width: 150px;
    height: 30px;
    background-image: url("../../assets/img/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.dark .navbar .logo {
    background-image: url("../../assets/img/logo-dark.png");
}

.navbar-links {
    justify-self: start;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    list-style: none;
    margin-bottom: 35px;
}

.navbar-links a,
.navbar-links i {
    color: #a7a7a7;
    transition: color ease-in-out 0.2s;
}

.navbar-links a {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.navbar-links li:hover a,
.navbar-links li:hover i {
    color: #003eff;
}

.navbar-links .active a,
.navbar-links .active i {
    color: #003eff;
}

.navbar-links i {
    margin-right: 12px;
    width: 20px;
}
