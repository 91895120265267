.duotone {
    z-index: -1;
    width: 100%;
    height: 100vh;
    position: absolute;
    background-image: url("../../assets/img/auth_background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.duotone::before {
    height: 100%;
    display: block;
    background-color: #00ff9b;
    content: '';
    mix-blend-mode: darken;
}

.duotone::after {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: block;
    background-color: #003eff;
    content: '';
    mix-blend-mode: lighten;
}

.container {
    width: 50%;
    height: 100vh;
    background-color: white;
    display: grid;
    grid-template: 1fr 3fr 1fr / 1fr;
}

.align-right {
    float: right;
}

.container .logo {
    width: 300px;
    justify-self: center;
    align-self: center;
}

h1 {
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 700;
}

.container form {
    height: 100%;
    padding: 0 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.submit-button {
    align-self: center;
    margin-bottom: 40px;
}

.toggle-auth-link {
    font-size: 14px;
    color: #a7a7a7;
    background: none;
    border: none;
    cursor: pointer;
}