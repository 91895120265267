.button {
    background-color: #003eff;
    color: white;
    border-style: none;
    border-radius: 100px;
    padding: 11px 18px;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: transform ease-out 0.1s;
    text-decoration: none;
}

.button i {
    color: white;
    margin-right: 10px;
}

.button:hover {
    transform: scale(1.05);
}

.button:disabled {
    transform: scale(1);
    cursor: default;
    background-color: #5b83ff;
}

.button:focus {
    outline: none;
    transform: scale(1.05);
}

.fancy-button {
    box-shadow: 4px 4px 12px -5px #0032ce;
    background-image: linear-gradient(#003eff 0%, #0032ce 75%, #002496 100%);
}

.fancy-button.danger {
    box-shadow: 4px 4px 12px -5px #d63a3a;
    background-image: linear-gradient(#ff4747 0%, #d63a3a 75%, #b73232 100%);
}

.button-sm {
    font-size: 11px;
    padding: 8px 15px;
}