.main-container {
    height: 100vh;
    overflow: hidden;
    background-color: #fdfafd;
    transition: background-color ease-in-out 0.2s;
}

.main-container.dark {
    background-color: #0a0a0a;
}

.content-container {
    width: 100vh;
    display: flex;
    transform: width ease-in-out 0.3s;
}

.content {
    width: calc(100vw - 75px * 2);
    height: calc(100vh - 100px - 75px * 2);
    margin-top: 100px;
    padding: 75px 75px;
    overflow: auto;
}

.content h1 {
    font-size: 35px;
}