.day-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.day-details span:first-child,
.day-details .temp span:nth-child(1),
.day-details .temp span:nth-child(2) {
    font-weight: 600;
}

.day-details span:first-child {
    margin-bottom: 10px;
}

.day-details span:nth-child(3) {
    margin-bottom: 10px;
}

.day-details .temp span {
    margin-bottom: 0;
    padding: 0 10px;
}

.day-details .temp span:nth-child(1),
.day-details .temp span:nth-child(2) {
    font-size: 18px;
}

.day-details .temp span:nth-child(3),
.day-details .temp span:nth-child(4) {
    font-size: 14px;
}

.day-details .temp {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    justify-items: center;
}

.day-details img {
    width: 100px;
}